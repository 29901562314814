@import '../../../../scss/theme-bootstrap';

.back-to-top {
  width: 0px;
  height: 20px;
  cursor: pointer;
  position: fixed;
  bottom: 40%;
  right: 10px;
  opacity: 0;
  transition: opacity 0.3s, width 0s 0.3s;
  margin: 0 auto;
  z-index: 99;
  @include icon('arrow-white--up');
  font-size: 25px;
  &:before {
    background: $color-white;
    border-radius: 50%;
  }
  @include breakpoint($medium-up) {
    font-size: 30px;
    width: 40px;
    right: 15px;
  }
  &.active {
    opacity: 1;
    width: 20px;
    @include breakpoint($medium-up) {
      width: 40px;
    }
    transition: opacity 0.3s;
  }
}
